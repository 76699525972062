.cards {
  width: 100%;
  padding-top: 125%;
  position: relative;
  // overflow-x: hidden;
}

.swiping-card {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fafafd;
  transform: scale(0.9);
  top: -8%;
  transition: all 0.3s ease-in-out;
  z-index: 50;
  backface-visibility: hidden;
  perspective: 1000;

  &.swiping-card--active {
    transform: scale(1);
    z-index: 100;
    top: 0;
  }

  &--removed-left {
    transform: rotate(-7deg) translate3d(-150%, -2%, 0);
    opacity: 0;
  }

  &--removed-right {
    transform: rotate(7deg) translate3d(150%, 2%, 0);
    opacity: 0;
  }

  &.swiping-card--remove-left {
    animation: swipe-left 0.5s ease-in-out both;
  }

  &.swiping-card--remove-right {
    animation: swipe-right 0.5s ease-in-out both;
  }

  &.swiping-card--back-in-left {
    transform: scale(1);
    z-index: 150;
    top: 0;
    animation: swipe-left 0.5s ease-in-out reverse;
  }

  &.swiping-card--back-in-right {
    transform: scale(1);
    z-index: 150;
    top: 0;
    animation: swipe-right 0.5s ease-in-out reverse;
  }

  &.swiping-card--reactivate {
    animation: swipe-right 0.5s ease-in-out both;
  }

  &.swiping-card--inactive {
    transform: scale(0.85);
    z-index: 30;
  }

  &::after {
    position: absolute;
    bottom: 20px;
    content: "";
    width: 40px;
    height: 5px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.4);
    left: 50%;
    transform: translateX(-50%);
  }
}

@keyframes swipe-left {
  0% {
    transform: rotate(0) translate3d(0, 0, 0) scale(1);
  }

  10% {
    transform: rotate(-3deg) translate3d(-5%, -2%, 0);
  }

  50% {
    transform: rotate(-7deg) translate3d(-15%, -2%, 0);
  }

  90% {
    transform: rotate(-7deg) translate3d(-150%, -2%, 0);
    opacity: 0.9;
  }

  100% {
    transform: rotate(-7deg) translate3d(-150%, -2%, 0);
    opacity: 0;
  }
}

@keyframes swipe-right {
  0% {
    transform: rotate(0) translate3d(0, 0, 0) scale(1);
  }

  10% {
    transform: rotate(3deg) translate3d(5%, 2%, 0);
  }

  50% {
    transform: rotate(7deg) translate3d(15%, 2%, 0);
  }

  90% {
    transform: rotate(7deg) translate3d(150%, 2%, 0);
    opacity: 0.9;
  }

  100% {
    transform: rotate(7deg) translate3d(150%, 2%, 0);
    opacity: 0;
  }
}

// @keyframes swipe-right{

//    0%{
//       transform: rotate(-7deg) translate3d(-150%, -2% , 0);
//       opacity: 0;
//   }

//      10%{
//     transform: rotate(-7deg) translate3d(-150%, -2% , 0);
//      opacity: .9;
//   }

//    50%{
//     transform: rotate(-7deg) translate3d(-15%, -2% , 0);
//      opacity: 1;
//   }

//     90%{
//     transform: rotate(-3deg) translate3d(-5%, -2% , 0);
//   }

//   100%{
//     transform: rotate(0deg) translate3d(0, 0 , 0);
//   }

// }

.swiping-card_details {
  padding-top: 100px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}

.swiping-card__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin-bottom: 45px;
}
