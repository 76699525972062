:root {
  --primary-500: 51, 32, 137; //catlog primary 500
  --accent-yellow-500: 239, 148, 15; //catlog accent-yellow-500
  --add-to-cart: 51, 32, 137; //catlog primary 500
  --discount-badge: 234, 94, 139; //catlog accent-red-300
  --cart-badge: 191, 6, 55; //catlog accent-red-500
  --success: 57, 181, 136; //catlog accent-green-500
  // --primary-color: 51, 32, 137;
  // --secondary-color: 239, 148, 15;
}

$colors: (
  primary-500: var(--primary-500),
  accent-yellow-500: var(--accent-yellow-500),
  add-to-cart: var(--add-to-cart),
  discount-badge: var(--discount-badge),
  cart-badge: var(--cart-badge),
  success: var(--success),
);

@each $color-name, $color-value in $colors {
  .hover\:bg-#{$color-name}:hover,
  .bg-#{$color-name} {
    background-color: rgba($color-value, var(--tw-bg-opacity)) !important;
  }

  .hover\:text-#{$color-name}:hover,
  .text-#{$color-name} {
    color: rgba($color-value, var(--tw-text-opacity)) !important;
  }

  .hover\:border-#{$color-name}:hover,
  .border-#{$color-name} {
    border-color: rgba($color-value, var(--tw-border-opacity)) !important;
  }
}
