@keyframes scrolling-images {
  50% {
    transform: translateY(var(--scroll-val));
  }
}

@keyframes scrolling-images-rev {
  0% {
    transform: translateY(var(--scroll-val));
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(var(--scroll-val));
  }
}

.anim-scrolling-images {
  --scroll-val: -10%;
  animation: scrolling-images 20s linear forwards infinite;
  @include lg {
    --scroll-val: -35%;
    animation: scrolling-images 60s linear forwards infinite;
  }
}

.anim-scrolling-images-rev {
  --scroll-val: -10%;
  animation: scrolling-images-rev 20s linear forwards infinite;
  @include lg {
    --scroll-val: -35%;
    animation: scrolling-images-rev 60s linear forwards infinite;
  }
}

@keyframes scale-up {
  33% {
    transform: scale(1.2);
  }
  66% {
    transform: scale(0.8);
  }
}

.anim-scale-up {
  animation: scale-up forwards 0.8s ease-out;
}

@keyframes auth-display-image-side {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  60% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0) rotate(var(--rotate-deg));
  }
}

@keyframes auth-display-image-center {
  to {
    opacity: 1;
    transform: translate(-50%, -15%);
  }
}

.auth-display-image {
  --rotate-deg: -10deg;
  opacity: 0;
  transform: translateY(20%);
  animation: auth-display-image-side 2s 1s forwards ease-out;
}

.auth-display-image--3 {
  --rotate-deg: 10deg !important;
}

.auth-display-image--2 {
  opacity: 0;
  transform: translate(-50%, 20%);
  animation: auth-display-image-center 1s 1s forwards ease-out;
}

@keyframes draw-checkmark {
  to {
    stroke-dashoffset: 0;
  }
}

.anim-draw-checkmark {
  stroke-dasharray: 50;
  stroke-dashoffset: 50;
  animation: draw-checkmark 0.8s 0.2s ease-out forwards;
}

@keyframes x-pulse {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.anim-pulse {
  transform: scale(0.8);
  animation: x-pulse 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}

@keyframes modal-wrapper {
  100% {
    opacity: 1;
    // pointer-events: auto;
  }
}

@keyframes modal-wrapper-rev {
  100% {
    opacity: 0;
    // pointer-events: auto;
  }
}

.modal-wrapper-anim {
  opacity: 0;
  animation: modal-wrapper 0.3s forwards ease-in-out;
}

.modal-wrapper-anim-rev {
  opacity: 1;
  animation: modal-wrapper-rev 0.3s forwards ease-in-out;
}

@keyframes modal-body {
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes modal-body-rev {
  100% {
    opacity: 0.7;
    transform: scale(1) translateY(40px);
    @include sm {
      transform: scale(0.9) translateY(40px);
    }
  }
}

.modal-body-anim {
  // show ? "opacity-100 sm:scale-100 translate-y-0" : "opacity-70 sm:scale-90 translate-y-10"
  opacity: 0.7;
  transform: scale(1) translateY(40px);
  @include sm {
    transform: scale(0.9) translateY(40px);
  }
  animation: modal-body 0.5s forwards ease-in-out;
}

.modal-body-anim-rev {
  opacity: 1;
  transform: scale(1) translateY(0);
  animation: modal-body-rev 0.5s forwards ease-in-out;
}

/* Shine */

.animate-shine:after {
  content: "";
  top: 0;
  transform: translateX(100%);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  animation: slide 1.5s infinite 3s;
  /* 
  CSS Gradient - complete browser support from http://www.colorzilla.com/gradient-editor/ 
  */
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(50%, rgba(255, 255, 255, 0.8)),
    color-stop(99%, rgba(128, 186, 232, 0)),
    color-stop(100%, rgba(125, 185, 232, 0))
  );
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  );
  /* Opera 11.10+ */
  background: -ms-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  );
  /* IE10+ */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  );
  /* W3C */
  filter: unquote(
    "progid: DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8', GradientType=1)"
  );
  /* IE6-9 */
}

/* animation */

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(-50%, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-52%, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(-46%, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-58%, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(-42%, 0, 0);
  }
}

.play-button-ripples {
  position: relative;

  &::before,
  &::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 115%;
    border-radius: 100px;
    content: "";
    border: solid rgb(255, 255, 255, 0.7) 4px;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
  }

  &::before {
    animation: ripple 3s cubic-bezier(0.65, 0, 0.34, 1) infinite;
  }

  &::after {
    animation: ripple 3s 0.5s cubic-bezier(0.65, 0, 0.34, 1) infinite;
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    // transform: scale3d(0.9, 0.75, 1) translate(-50%, -50%);
    transform: scale3d(0.9, 0.75, 1) translate(-55%, -65%);
  }

  to {
    opacity: 0;
    // transform: scale3d(1.15, 1.3, 1) translate(-50%, -50%);
    transform: scale3d(1.15, 1.3, 1) translate(-42.5%, -37.5%);
  }
}

@keyframes bounce {
  0%,
  50% {
    transform: translateY(0);
  }

  53% {
    transform: translateY(-4px);
  }

  56% {
    transform: translateY(0);
  }

  59% {
    transform: translateY(4px);
  }

  62% {
    transform: translateY(0);
  }

  65% {
    transform: translateY(-4px);
  }

  68% {
    transform: translateY(0);
  }

  71% {
    transform: translateY(4px);
  }

  74%,
  100% {
    transform: translateY(0);
  }
}

.bounce-button {
  animation: bounce 5s linear infinite;
}

.modal-full-screen-anim {
  opacity: 0;
  transform-origin: center top;
  // transform: translateY(10%) scale(0.95);
  animation: modal-full-screen 0.5s forwards ease-out;
}

.modal-full-screen-anim-rev {
  opacity: 1;
  transform-origin: center top;
  // transform: translateY(0) scale(1);
  animation: modal-full-screen-rev 0.5s forwards ease-out;
}

@keyframes modal-full-screen {
  100% {
    opacity: 1;
    // transform: translateY(0) scale(1);
    // pointer-events: auto;
  }
}

@keyframes modal-full-screen-rev {
  100% {
    opacity: 0;
    // transform: translateY(10%) scale(0.95);
    // pointer-events: auto;
  }
}

.transfer-waiting-loader {
  // animation-timing-function: transfer-loader;
  transform: translateX(-100%);
  animation-name: transfer-loader;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-iteration-count: infinite;
}

@keyframes transfer-loader {
  0% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(400%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.floating-emoji {
  // Use CSS variables to make the animation customizable
  --speed: 10s; // Default speed, can be customized
  --start: 50%; // Default starting y position, can be customized
  --amplitude: 75%; // Default amplitude, can be customized
  --delay: 1s;

  transform: translate(0, 0);
  animation: bounceEmoji var(--speed) infinite ease-in-out;
}

// Define the keyframes for the bounce animation using the start-y and amplitude variables
@keyframes bounceEmoji {
  0%,
  100% {
    transform: translate(0, calc(var(--start) - (var(--amplitude))));
  }
  50% {
    transform: translate(0, calc(var(--start) + (var(--amplitude))));
  }
}

// Define the keyframes for the bounce animation using the start-y and amplitude variables
// @keyframes bounceEmoji {
//   0%,
//   100% {
//     transform: translate(0, 0);
//   }
//   50% {
//     transform: translate(0, 100%);
//   }
// }
