@keyframes word-up {
  from {
    opacity: 0;
    transform: translateY(var(--from));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.anim-word-up:not(.hide-for-observer .anim-word-up) {
  --delay: 0s;
  --from: -100%;
  --duration: 0.75s;
  opacity: 0;
  transform: translateY(var(--from));
  -webkit-backface-visibility: hidden;
  position: relative;
  animation: word-up cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards;
  animation-delay: var(--delay);
  animation-duration: var(--duration);
}

@keyframes item-down {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.anim-item-down:not(.hide-for-observer .anim-item-down) {
  --delay: 0s;
  opacity: 0;
  transform: translateY(50%);
  -webkit-backface-visibility: hidden;
  position: relative;
  animation: item-down 0.75s var(--delay) cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards;
}

@keyframes fade-out {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.anim-fade-up {
  --delay: 0s;
  opacity: 0;
  animation: fade-out 0.7s cubic-bezier(0.755, 0.05, 0.855, 0.06) forwards;
  animation-delay: var(--delay);
}

.anim-fade-up-fast {
  --delay: 0s;
  opacity: 0;
  animation: fade-out 0.3s var(--delay) cubic-bezier(0.755, 0.05, 0.855, 0.06) forwards;
}

.anim-fade-in {
  --delay: 0s;
  opacity: 0;
  animation: fade-out 0.3s var(--delay) cubic-bezier(0.755, 0.05, 0.855, 0.06) reverse;
}

.anim-fade-in-fast {
  --delay: 0s;
  opacity: 0;
  animation: fade-out 0.5s var(--delay) cubic-bezier(0.755, 0.05, 0.855, 0.06) reverse;
}

.slide-text-in {
  --from: 30%;
  --duration: 0.75s;
  --delay: 0s;
  opacity: 0;
  transform: translateY(var(--from));
  animation: slide-text-in cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards;
  animation-delay: var(--delay);
  animation-duration: var(--duration);
  -webkit-backface-visibility: hidden;
}

@keyframes slide-text-in {
  from {
    opacity: 0;
    transform: translateY(var(--from));
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.image-appear:not(.hide-for-observer .image-appear) {
  --delay: 0s;
  transform-origin: center center;
  transform: scale(0.85);
  opacity: 0;
  animation: fade-image-in 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  animation-delay: var(--delay);
}

@keyframes fade-image-in {
  from {
    transform: scale(0.85);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 100;
  }
}

.story-image-appear {
  --delay: 0s;
  --to: 0;
  --from: 10%;
  transform-origin: center center;
  transform: translate3d(-50%, var(--to), 0);
  opacity: 0;
  animation: fade-story-image 0.5s ease-in forwards;
  animation-delay: var(--delay);
}

@keyframes fade-story-image {
  from {
    transform: translate3d(-50%, var(--from), 0);
    opacity: 0;
  }

  to {
    transform: translate3d(-50%, var(--to), 0);
    opacity: 100;
  }
}
