@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  /* Text classes */
  .body-padding {
    @apply px-5 sm:px-8 md:px-10 lg:px-25;
  }

  .body-y-padding{
    @apply py-12.5 sm:py-17.5 md:py-22.5 lg:py-25;
  }

  .negative-body-padding {
    @apply px-5 sm:px-8 md:px-10 lg:px-25 -mx-5 sm:-mx-8 md:-mx-10 lg:-mx-25;
  }

  .container{
    @apply max-w-[1200px] mx-auto w-full;
  }

  .heading-one {
    @apply text-[44px] sm:text-[52px] md:text-[66px] lg:text-[77px] xl:text-[84px];
    line-height: 1.1 !important;
  }

  .heading-two {
    @apply text-[37px] sm:text-[47px] md:text-[60px] lg:text-[70px] xl:text-[74px];
    line-height: 1.1 !important;
  }

  .heading-three {
    @apply text-[34px] sm:text-[40px] md:text-[52px] lg:text-[62px] xl:text-[65px];
    line-height: 1.25 !important;
  }

  .heading-five {
    @apply text-2lg sm:text-[26px] md:text-[30px] lg:text-[32px] xl:text-[34px];
    line-height: 1.12 !important;
  }

  .follow-text {
    @apply text-base sm:text-xl md:text-2lg lg:text-2xl xl:text-[26px];
    line-height: 1.375 !important;
  }

  .hero-text{
    @apply text-base sm:text-lg md:text-xl lg:text-2lg xl:text-2xl;
    line-height: 1.375 !important;
  }

  .body-text{
    @apply md:text-1sm lg:text-base xl:text-lg;
  }

  .sub-text {
    @apply text-base sm:text-lg lg:text-xl;
    line-height: 1.375 !important;
  }

  .section-spacing{
    @apply mb-12.5 sm:mb-17.5 md:mb-22.5 lg:mb-25;
  }

  .item-radius{
    @apply rounded-15 sm:rounded-20 md:rounded-3xl lg:rounded-[30px];
  }
}