.spinner {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid rgba(#fafafa, 0.9);
  border-top-color: currentColor;
  animation: 1.5s spin infinite linear;

  &--sm {
    width: 15px;
    height: 15px;
    border-width: 2px;
  }

  &--lg {
    width: 45px;
    height: 45px;
    border-width: 4px;

    @include sm {
      width: 60px;
      height: 60px;
      border-width: 6px;
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
