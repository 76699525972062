:root {
  --page-color: #332089;
  --page-pastel: #f7f5ff;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  --tw-border-opacity: 1;
}

* {
  // -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

.radial-bg {
  background: linear-gradient(117.78deg, #fff9e6 4.58%, rgba(255, 255, 255, 0) 86.1%),
    radial-gradient(96.79% 217.39% at 100% 104.38%, rgba(222, 216, 255, 0.4) 0%, #ffffff 100%);
  background-size: 250% 250%;
  animation: gradient 8s ease infinite;
}

.radial-bg-chowbot {
  background: linear-gradient(117.78deg, #f6fffc 4.58%, rgba(255, 255, 255, 0) 86.1%),
    radial-gradient(96.79% 217.39% at 100% 104.38%, #fff9e6 0%, #ffffff 100%);
  background-size: 250% 250%;
  animation: gradient 8s ease infinite;
}

.front-header {
  position: relative;
  display: block;

  &::after {
    position: absolute;
    z-index: -1;
    height: 70%;
    top: 0;
    left: 0;
    content: "";
    // background: pink;
    width: 100%;
    // background: linear-gradient(117.78deg, #fff9e6 4.58%, rgba(255, 255, 255, 0) 86.1%),
    //   radial-gradient(96.79% 217.39% at 100% 104.38%, rgba(222, 216, 255, 0) 0%, #ffffff 100%);
    // background: linear-gradient(117.78deg, #fff9e6 4.58%, rgba(255, 255, 255, 0) 86.1%),
    //   radial-gradient(96.79% 217.39% at 100% 104.38%, rgba(222, 216, 255, 0) 0%, #ffffff 100%);
    // background: linear-gradient(117.78deg, #fff9e6 4.58%, rgba(255, 255, 255, 0) 86.1%),
    //   radial-gradient(96.79% 217.39% at 100% 104.38%, rgba(222, 216, 255, 0.3) 0%, #ffffff 100%);
    @extend .radial-bg;
  }

  &--chowbot::after {
    @extend .radial-bg-chowbot;
  }

  &--why-catlog {
    &::after {
      height: 79%;
    }
  }

  &--about-us {
    &::after {
      height: 100%;
    }
  }

  & > * {
    position: relative;
    z-index: 10;
    background: transparent;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes slide-icon-in {
  from {
    opacity: 0;
    transform: translateY(80%) scale(var(--scale));
  }

  to {
    opacity: 1;
    transform: translateY(50%) scale(var(--scale));
  }
}

.text-pre-show {
  --from: 25%;
  opacity: 0;
  transform: translateY(var(--from));
}

.icon-pre-show {
  opacity: 0;
  transform: translateY(80%) scale(var(--scale)) !important;
}

.slide-icon-in {
  animation: 0.7s slide-icon-in cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
}

.header-video {
  opacity: 0;
  transform: scale(0.85) translateY(25%);
  -webkit-backface-visibility: hidden;
  animation: header-video-play 2s 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
  transform-origin: center center;
  will-change: transform;

  &.mobile {
    transform-origin: bottom right;
  }

  @include sm {
    transform-origin: center center;
  }

  // cubic-bezier(0.455, 0.03, 0.515, 0.955)
  // transform: matrix(1, 0, 0, 1, 0, 0); matrix(0.98, 0.01, -0.19, 1, 0, 0)
}

@keyframes header-video-play {
  0% {
    opacity: 0;
    transform: scale(0.85) translateY(25%);
  }

  30% {
    opacity: 1;
    transform: scale(0.85) translateY(5%);
  }

  50% {
    opacity: 1;
    transform: scale(0.85) translateY(5%);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.hero-image-with-shadow {
  -webkit-backface-visibility: hidden;
  -webkit-filter: drop-shadow(0 4px 50px rgb(0, 0, 0, 0.1));
  filter: drop-shadow(0 4px 50px rgb(0, 0, 0, 0.1));
  transform: translateZ(0);
}

.feature-wrapper {
  perspective: 1000px;

  & > * {
    perspective: 8000px;
  }

  .feature-flip {
    opacity: 0;
    transform: translateY(-10px) rotateX(45deg);
    transform-origin: top;
    position: relative;
  }
}

.sell-item {
  figure {
    transition: all 500ms ease-out;
    transform: scale(1) rotate(0);
  }

  &:hover > figure {
    filter: blur(5px);
    transform: scale(1.2) rotate(-4deg);
  }
}

.item-overlay {
  transition: all 500ms ease-in-out;
  background: linear-gradient(180deg, rgba(6, 5, 56, 0) 0%, rgba(6, 5, 56, 0.7) 50%, rgba(6, 5, 56, 0.7) 100%);
  cursor: pointer;

  &:hover {
    transform: translateY(-50%);
  }
}

.cta {
  background-repeat: no-repeat;
  background-size: cover;

  &.primary-cta {
    background-image: url("/images/patterns/light-purple-pattern.svg");
  }

  &.yellow-cta {
    background-image: url("/images/patterns/light-yellow-pattern.svg");
  }

  &.orange-cta {
    background-image: url("/images/patterns/light-orange-pattern.svg");
  }

  &.red-cta {
    background-image: url("/images/patterns/light-red-pattern.svg");
  }

  &.green-cta {
    background-image: url("/images/patterns/light-green-pattern.svg");
  }

  & > * {
    position: relative;
    z-index: 10;
  }

  & > img {
    position: absolute;
    z-index: 0;
  }
}

.extra-item {
  transition: 150ms all ease-out;
  cursor: pointer;

  &:hover {
    // transform: rotate(-2deg);
    background: var(--page-pastel);
  }
}

.hiw-step {
  perspective: 1000px;
  position: absolute;
  transition: all 500ms ease-out;

  &.active {
    transform: translateY(0);
  }

  &.next {
    transform: translateY(100%);
  }

  &.past {
    transform: translateY(-100%);
  }

  &:first-child {
    position: relative;

    &.active,
    &.next {
      transform: translateY(0);
    }

    &.past {
      transform: translateY(-100%);
    }
  }

  @include md {
    position: relative !important;
    transform: translateY(0) !important;
  }
}

.nav-dropdown {
  opacity: 0;
  pointer-events: none;
  top: 100%;
  transition: 0.3s ease-out all;
  box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.1);
  perspective: 500px;
  transform: translate(-50%, 10px) rotateX(-15deg);
}

.dropdown-links {
  transform: translateY(30%);
  opacity: 0;

  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      transition: all 0.4s #{$i * 0.15}s ease-out;
    }
  }
}

.nav-item {
  perspective: 2000px;
}

.nav-item:hover > .nav-dropdown {
  opacity: 1;
  transform: translate(-50%, 0) rotateX(0deg);
  pointer-events: unset;

  .dropdown-links {
    transform: translateY(0);
    opacity: 1;
  }
}

.front-nav-toggle {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  padding: 7px 6px;
  position: relative;
  transition: 0.3s ease-out all;

  &-inner {
    display: inline-block;
    height: 2px;
    width: 100%;
    background: #000;
    border-radius: 10px;
    position: relative;
    transition: 0.3s ease-out all;

    &::before,
    &::after {
      position: absolute;
      content: "";
      top: -6px;
      left: 0;
      height: 2px;
      width: 100%;
      background: #000;
      transition: 0.3s ease-out all;
    }

    &::after {
      bottom: -6px;
      top: unset;
    }
  }
}

.mobile-nav {
  transform: translateY(-100%);
  overflow: hidden;
  transition: 0.5s cubic-bezier(0.755, 0.05, 0.855, 0.06) all;
}

// .mobile-nav-item,
// .mobile-nav-item-three,
// .mobile-nav-four {
//   transform: translateY(-20%);
//   opacity: 0;
// }

// .mobile-nav-item {
//   @for $i from 1 through 5 {
//     &:nth-child(#{$i}) {
//       transition: all 0.4s #{$i * 0.1 + 0.5}s ease-out;
//     }
//   }
// }

// .mobile-nav-item-three {
//   @for $i from 1 through 5 {
//     &:nth-child(#{$i}) {
//       transition: all 0.4s #{$i * 0.1 + 0.9}s ease-out;
//     }
//   }
// }

.mobile-nav-item-four {
  transition: all 0.4s 2.2s ease-out;
}

#front-nav-toggle-checkbox:checked ~ .front-nav-toggle {
  // background: #000;
  // border-radius: 50%;

  & > .front-nav-toggle-inner {
    background: transparent;

    &::before {
      transform: translate3d(0, 6px, 0) rotate(45deg);
    }

    &::after {
      transform: translate3d(0, -6px, 0) rotate(-45deg);
    }
  }
}

#front-nav-toggle-checkbox:checked ~ .mobile-nav {
  transform: translateY(0);

  .mobile-nav-item,
  .mobile-nav-item-three,
  .mobile-nav-item-four {
    transform: translateY(0);
    opacity: 1;
  }
}

.feature-image {
  padding-top: 75%;
}

.mockup-shadow {
  -webkit-backface-visibility: hidden;
  -webkit-filter: drop-shadow(0 100px 250px rgb(0, 0, 0, 0.1));
  filter: drop-shadow(0 100px 250px rgb(0, 0, 0, 0.1));
  transform: translateZ(0);
}

.marquee-container {
  &:hover {
    .marquee {
      -webkit-animation-play-state: paused;
      -moz-animation-play-state: paused;
      -o-animation-play-state: paused;
      animation-play-state: paused;
      // cursor: pointer;
    }
  }

  .marquee {
    --amount: 400%;
    --n-amount: -400%;
    top: 0;
    left: 0;
    width: var(--amount);
    position: absolute;
    white-space: nowrap;
    animation: marquee 50s linear infinite;
    transform: translate3d(0, 0, 0);

    @media screen and (min-width: 1801px) {
      --amount: 200%;
      --n-amount: -125%;
    }

    @media screen and (min-width: 1201px) and (max-width: 1800px) {
      --amount: 250%;
      --n-amount: -250%;
    }

    @media screen and (min-width: 801px) and (max-width: 1200px) {
      --amount: 300%;
      --n-amount: -300%;
    }

    @media screen and (min-width: 551px) and (max-width: 800px) {
      --amount: 320%;
      --n-amount: -320%;
    }
  }

  .marquee2 {
    animation-delay: 20s;
    transform: translate3d(var(--amount), 0, 0);
    animation: marquee2 50s linear infinite;
  }
  b {
    padding-left: 10px;
  }
}

@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes marquee2 {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.customer-card:hover + .customer-card-name {
  transform: translate(-50%, 0);
  opacity: 100;
  // &::after {
  //   content: "Hello";
  //   display: block;
  //   position: absolute;
  //   top: 100%;
  //   left: -50%;
  //   background: black;
  //   width: 100%;
  //   color: white;
  // }
}

.hide-for-observer {
  visibility: hidden;
}

.home-feature-group {
  // perspective: 2000px;

  .prevent-card-flip {
    perspective: 2500px;
  }

  .card-flip {
    --delay: 0s;
    perspective: 2500px;
    transform: translateY(-10px) rotateX(30deg);
    transform-origin: top;
    transition: all ease-in 0.3s;
    cursor: pointer;

    .feature-mockup {
      transition: all ease-out 0.3s;
    }

    &:hover {
      .feature-x-image {
        filter: blur(8px);
      }

      .feature-mockup {
        opacity: 100;

        figure {
          transform: translateY(0);
        }
      }
    }

    &.reverse {
      transform-origin: bottom;
    }

    &:not(.prevent-card-flip .card-flip) {
      animation: flip cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
      animation-duration: 0.4s;
      animation-delay: var(--delay);
    }
  }
}

@keyframes flip {
  0% {
    transform: translateY(-10px) rotateX(30deg);
  }

  // 80% {
  //   transform: translateY(2px) rotateX(-2deg);
  // }
  100% {
    transform: translateY(0px) rotateX(0deg);
  }
}

.chowbot-starburst {
  width: 150px; // Set the width of the element
  height: 150px; // Set the height of the element
  background-color: #f06; // Set a background color for visibility
  clip-path: polygon(
    50% 0%,
    58% 10%,
    68% 2%,
    68% 20%,
    84% 22%,
    72% 32%,
    84% 45%,
    68% 38%,
    68% 56%,
    58% 48%,
    50% 60%,
    42% 48%,
    32% 56%,
    32% 38%,
    16% 45%,
    28% 32%,
    16% 22%,
    32% 20%,
    32% 2%,
    42% 10%
  ); // Set the vertices for the clip-path
  display: flex;
  justify-content: center;
  align-items: center;
}

// .starburst {
//   font: 4em/1.4 'Lobster', georgia, serif;
//   background: #202020;
//   width: 2.5em;
//   height: 2.5em;
//   text-align: center;
//   color: #fff;
// }

.starburst-container {
  animation: starspin 15s infinite ease-out;
}

.starburst {
  transform: rotate(-45deg);
}

.starburst,
.starburst span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.starburst span {
  width: 100%;
  height: 100%;
  background: inherit;
  transform: rotate(45deg);
}

.starburst:before,
.starburst:after,
.starburst span:before,
.starburst span:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  z-index: -1;
  transform: rotate(30deg);
}

.starburst:after {
  transform: rotate(-30deg);
}

.starburst span:after {
  transform: rotate(30deg);
}
.starburst span:before {
  transform: rotate(-30deg);
}

// @keyframes starspin {
//   0%,
//   100% {
//     transform: rotate(45deg); // Start and end at initial position
//   }

//   15%,
//   85% {
//     transform: rotate(45deg);
//   }
//   20%,
//   80% {
//     transform: rotate(180deg); // Rotate halfway for both clockwise and anticlockwise
//   }
//   25%,
//   75% {
//     transform: rotate(360deg); // Complete rotation clockwise
//   }
//   45%,
//   46%,
//   47%,
//   48%,
//   49%,
//   50%,
//   51%,
//   52%,
//   53%,
//   54%,
//   55% {
//     transform: rotate(45deg); // Return to initial position
//   }
// }

@keyframes starspin {
  0%,
  100% {
    transform: rotate(0deg); // Start and end at initial position
  }
  20%,
  80% {
    transform: rotate(180deg); // Rotate halfway for both clockwise and anticlockwise
  }
  30%,
  70% {
    transform: rotate(360deg); // Complete rotation clockwise
  }
  45%,
  50%,
  55% {
    transform: rotate(0deg); // Return to initial position
  }
}
